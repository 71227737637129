import React from "react";
import SliderOne from "../Components/SliderOne";
import MultiSlider from "../Components/MultiSlider";
import BestHomeCare from "../Components/BestHomeCare";
import BookAppointment from "../Components/BookAppointment";
import Numbering from "../Components/Numbering";
import WhymostPeople from "../Components/WhymostPeople";
import NextEvents from "../Components/NextEvents";
import Testimonials from "../Components/Testinomials";
import Blog from "../Components/Blog";
import Appointment from "../Components/Appointment";

function Home() {
  return (
    <>
      <SliderOne />
      <MultiSlider />
      <BestHomeCare />
      <BookAppointment />
      {/* <Numbering /> */}
      <WhymostPeople />
      {/* <NextEvents /> */}
      <Testimonials />
      {/* <Blog /> */}
      <Appointment />
    </>
  );
}

export default Home;
