import React from "react";
import BestHomeCare from "../Components/BestHomeCare";
import PageBanner from "../Components/PageBanner";
import Callnow from "../Components/Callnow";
import Servicelist from "../Components/Servicelist";

function Caregivernonmedical() {
  return (
    <>
      <PageBanner name="Care Giver Non Medical" />
      <div className="row">
        <div className="col-xl-4  col-md-4  col-lg-4 ml-5" >
        <Servicelist />
        </div>
        <div className="col-xl-6  col-md-8  col-lg-4">
        <div className="service-details__main">
                            <div className="service-details__image">
                                <img src="assets/images/services/service-d-6-1.jpg" className="img-fluid" alt=""></img>
                            </div>
                            <div className="service-details__content">
                            <h3>Care Giver Non Medical</h3>                                
                                <p>At Swarnim Ayu we also provide non-medical support services to Elderly in their homes & these services focus on helping with daily living activities and maintaining quality of life.</p>
                                <p>
                                 <h4>1.	Vital Signs Monitoring</h4>
                                 measurements of the body’s basic functions i.e.Body Temperature,Pulse Rate, Respiration Rate,
                                 Blood Pressure.
                                </p>
                                <p>
                                 <h4>2. Personal Care Assistance</h4>
                                 Bathing and grooming, Toileting assistance & Oral hygiene.
                                </p>
                                <p>
                                 <h4>3.Feeding Assistance & Medication Reminder</h4>
                                 Includes Feeding of Meals and supplement along with Medication on time and as Prescribed.
                                </p>
                                <p>
                                 <h4>4.Companionship</h4>
                                 Engaging in Conversation with Elderly along with reading aloud and   participating in hobbies and games.
                                </p>
                                <p>
                                 <h4>5.Transport Assistance</h4>
                                 Accompanying to appointments or social events.
                                </p>
                                <p>
                                <h4>6.Errands and Shopping</h4>
                                Picking up prescription for Elderly and running small errands for them.
                                </p>
                                <p>
                                <h4>7.Mobility Assistance</h4>
                                Help Elderly in Walking or Transferring and assistance with Exercises.
                                </p>
                                </div>
         </div>
        </div>
 
      </div>
      <br></br>
      <Callnow />
      {/* <BestHomeCare /> */}
    </>
  );
}

export default Caregivernonmedical;
