import React from 'react'

function Callnow() {
  return (
   <>
    <section className="cta-two">
            <img src="assets/images/shapes/cta-1-1-shape-1.png" className="cta-two__dot-1" alt=""></img>
            <img src="assets/images/shapes/cta-1-1-shape-2.png" className="cta-two__dot-2" alt=""></img>
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-lg-6 d-flex justify-content-center align-items-center wow fadeInLeft"
                        data-wow-duration="1500ms">
                        <div className="cta-two__image">
                            <img src="assets/images/resources/cta-1-1.jpg" alt=""></img>
                            <div className="cta-two__image-inner">
                                <div className="cta-two__image-content">
                                    <h3><span className="counter">78</span></h3>
                                    <p>Success Rate</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="cta-two__content">
                            <h3>Call Us Now !</h3>
                            <br></br>
                            {/* <div className='thm-btn cta-two__btn'>
                                <p>+91 9811799499</p>
                            </div> */}
                            <a className="thm-btn cta-two__btn">+91 9266829800</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   </>
  )
}

export default Callnow
