// App.js
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import WOW from "wowjs";
import "animate.css";
import Footers from "./Components/Footers";
import Header2 from "./Components/Header2";
import Home from "./Pages/Home";
import "./App.css";
import "./Navbar.css";
import Aboutus from "./Pages/Aboutus";
import OurTeam from "./Pages/OurTeam";
import Services from "./Pages/Services";
import Caregivermedical from "./Pages/Caregivermedical";
import Caregivernonmedical from "./Pages/Caregivernonmedical";
import Companion from "./Pages/Companion";
import Investment from "./Pages/Investment";
import Asset from "./Pages/Asset";
import Careathome from "./Pages/Legalservice";
import Seniorcare from "./Pages/Homeimprovement";
import Health from "./Pages/Equipment";
import Travel from "./Pages/Mutualfund";
import Donation from "./Pages/Donation";
import Payfee from "./Pages/Payfee";
import Income from "./Pages/Income";
import Legalservice from "./Pages/Legalservice";
import Homeimprovement from "./Pages/Homeimprovement";
import Equipment from "./Pages/Equipment";
import Mutualfund from "./Pages/Mutualfund";
import Insurance from "./Pages/Insurance";
import Oldagehome from "./Pages/Oldagehome";
import Contact from "./Pages/Contact";
import Termsandconditions from "./Pages/Termsandconditions";
import Privacyandpolicy from "./Pages/Privacyandpolicy";
import Paymentandrefundpolicy from "./Pages/Paymentandrefundpolicy";



function App() {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <Router>
      <div className="page-wrapper">
        <Header2 />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<Aboutus />} />
          <Route path="/team" element={<OurTeam />} />
          <Route path="/services" element={<Services />} />
          <Route path="/care-giver-medical" element={<Caregivermedical />} />
          <Route path="/care-giver-non-medical" element={<Caregivernonmedical />} />
          <Route path="/companion" element={<Companion />} />
          <Route path="/income" element={<Income />} />
          <Route path="/legal-service" element={<Legalservice/>} />
          <Route path="/home-improvement" element={<Homeimprovement />} />
          <Route path="/equipment" element={<Equipment />} />
          <Route path="/mutual-fund" element={<Mutualfund />} />
          <Route path="/insurance" element={<Insurance />} />
          <Route path="/investment" element={<Investment />} />
          <Route path="/asset" element={<Asset />} />
          <Route path="/old-age-home" element={<Oldagehome />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/termsandconditions" element={<Termsandconditions />} />
          <Route path="/privacyandpolicy" element={<Privacyandpolicy />} />
          <Route path="/Paymentandrefundpolicy" element={<Paymentandrefundpolicy />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footers />
      </div>
      <a href="#" data-target="html" className="scroll-to-target scroll-to-top">
        <i className="fa fa-angle-up"></i>
      </a>
    </Router>
  );
}

export default App;
