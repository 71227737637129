import React from 'react'
import PageBanner from "../Components/PageBanner";
import Callnow from '../Components/Callnow';
import Servicelist from "../Components/Servicelist";
function Oldagehome() {
  return (
    <div>
    <PageBanner name=" Old Age Home " />
    <div className="row">
        <div className="col-xl-4  col-md-4  col-lg-4 ml-5" >
        <Servicelist />
        </div>
        <div className="col-xl-4  col-md-4  col-lg-4">

</div>
 <div className="col-xl-4  col-md-4  col-lg-4">

</div>
      </div>
    <Callnow />
       <h1 className="text-center">Coming soon</h1>
    </div>
  )
}

export default Oldagehome
