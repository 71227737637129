import React, { useEffect } from "react";

function BestHomeCare() {
  return (
    <section className="about-three">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="about-three__content">
              <div className="block-title">
                <p className=""> About Us</p>
                <h3>
                  Best Home Care Center <br></br> with Love
                </h3>
              </div>
              <p>
              With the increase of life expectancy in the world, and smaller family sizes, elderly are left alone to take care of themselves. 
Swarnim Ayu has been made to help our seniors for the same. 
This start up is also by a Senior who understands these issues very well.
We have also taken the experience from Japan, Norway, England, USA and Italy and try to incorporate these with our offerings.
We are aiming to have the elderly care and support – in a transparent, reliable and  predictable manner.
COMPLIANCE is non negotiable in Swarnim Ayu.
We hope to become a PREFERRED CARE GIVER for the elderly.
              </p>
              
            </div>
          </div>
          <div className="col-lg-6 wow fadeInRight" data-wow-duration="1500ms">
            <div className="about-three__image ">
              <img
                src="assets/images/shapes/about-dot-3-1.png"
                className="about-dot-3-1"
                alt=""
              ></img>

              <img src="assets/images/resources/about-3-1.jpg" alt=""></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BestHomeCare;
