import React from "react";
import BestHomeCare from "../Components/BestHomeCare";
import PageBanner from "../Components/PageBanner";
import Callnow from "../Components/Callnow";
import Servicelist from "../Components/Servicelist";
function Homeimprovement() {
  return (
    <>
      <PageBanner name=" Home Improvement" />
      <div className="row">
        <div className="col-xl-4  col-md-4  col-lg-4 ml-5" >
        <Servicelist />
        </div>
        <div className="col-xl-6  col-md-8  col-lg-4">
        <div className="service-details__main">
                            <div className="service-details__image">
                                <img src="assets/images/services/service-d-6-1.jpg" className="img-fluid" alt=""></img>
                            </div>
                            <div className="service-details__content">
                            <h3>Home Improvement</h3>                                
                                <p>Elderly require special care. A normal residence needs several modifications. These are </p>
                                <p>
                                <ul>
                                <li>Anti slip floors </li>
                                <li>Special care in bathing and toilet areas.</li>
                                <li>Railings and support fixtures</li>
                                <li>Remote control for light and fans and other gadgets</li>
                                <li>Emergency alarm systems.</li>
                                <li>Lift to transfer from one floor to other.</li>
                                <li>Any other special requirement that may be there.</li>
                                </ul>
                                </p>
                                <p>
                                We have several tie-ups who are specialised in these. <br></br>
                                We will be happy to direct them to you to make the life of our dear ones more comfortable.
                                </p>
                                
                            </div>
         </div>
        </div>
 
      </div>
      <br></br>
      <Callnow />
      
      {/* <BestHomeCare /> */}
    </>
  );
}

export default Homeimprovement;
