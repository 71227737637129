import React from "react";

function PageBanner(props) {
  return (
    <section
      className="page-header"
      style={{
        backgroundImage: "url(assets/images/background/page-header-team-1.jpg)",
      }}
    >
      <div className="container">
        <h2>{props.name}</h2>
        <ul className="list-unstyled thm-breadcrumb">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <span>{props.name}</span>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default PageBanner;
