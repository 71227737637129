import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Header2 = () => {
  useEffect(() => {
    const handleLinkClick = (event) => {
      const navBar = document.querySelector(".navbar-collapse");
      const clickedElement = event.target;

      // Check if the clicked element is a dropdown toggle
      if (clickedElement.classList.contains("dropdown-toggle")) {
        return;
      }

      // Collapse the navbar if it is currently shown
      if (navBar.classList.contains("show")) {
        navBar.classList.remove("show");
      }
    };

    const mainNav = document.querySelector(".navbar-nav");
    mainNav.addEventListener("click", handleLinkClick);

    return () => {
      mainNav.removeEventListener("click", handleLinkClick);
    };
  }, []);

  return (
    <>
      <section className="topbar-one">
        <div className="container">
          <div className="topbar-one__left">
            <a href="#">
              <i className="fa fa-map-marker-alt"></i>
              192, Sector 56, Gurgaon 122011
            </a>
            <a href="#">
              <i className="far fa-clock"></i>
              Mon - Sat 9.00 - 18.00
            </a>
            <a href="tel:+251-235-3256">
              <i className="far fa-phone"></i>
              +91 9266829800
            </a>
          </div>

          <div className="topbar-one__right">
            <div className="topbar-one__social">
              <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fa fa-rss"></i>
              </a>
              <a href="#">
                <i className="fab fa-google-plus-g"></i>
              </a>
              <a href="#">
                <i className="fab fa-vimeo-v"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light ">
          <a className="navbar-brand" href="/home">
            <img src="assets/images/logo.png" alt="" width="219" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  About Us
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/about">
                    About Us
                  </Link>
                  <Link className="dropdown-item" to="/team">
                    Our Team
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Services
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/services">
                    Services
                  </Link>
                  <Link className="dropdown-item" to="/Care-giver-medical">
                    Care Giver - Medical
                  </Link>
                  <Link className="dropdown-item" to="/care-giver-non-medical">
                    Care Giver - Non Medical
                  </Link>
                  <Link className="dropdown-item" to="/companion">
                    Companion
                  </Link>
                  <Link className="dropdown-item" to="/income">
                    Income & Other Taxes
                  </Link>
                  <Link className="dropdown-item" to="/legal-service">
                    Legal Services
                  </Link>
                  <Link className="dropdown-item" to="/home-improvement">
                    Home Improvement
                  </Link>
                  <Link className="dropdown-item" to="/equipment">
                    Equipment Rental
                  </Link>
                  {/* <Link className="dropdown-item" to="/mutual-fund">
                    Mutual Fund Investments
                  </Link> */}
                  <Link className="dropdown-item" to="/insurance">
                    Insurance
                  </Link>
                  <Link className="dropdown-item" to="/investment">
                    Investment
                  </Link>
                  <Link className="dropdown-item" to="/asset">
                    Assest Management
                  </Link>
                  <Link className="dropdown-item" to="/old-age-home">
                    Old Age Home - Residential
                  </Link>
                </div>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#link">
                  News
                </a>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                Contact
                </Link>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#link">
                  Contact
                </a>
              </li> */}
            </ul>

             {/* <Link to="/oldagehome"></Link> */}
            <Link to="/contact" className="thm-btn main-nav-one__btn">
             Appointment  <i className="far fa-long-arrow-alt-right"></i></Link>
            {/* <a href="contact.html" className="thm-btn main-nav-one__btn">
              <i className="far fa-clock"></i>
              Appointment 
            </a> */}
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header2;
