import React from "react";

function Appointment() {
  return (
    <section className="appointment-one appointment-one__home-one">
      <img
        src="assets/images/shapes/appointment-map-1-1.png"
        className="appointment-one__map-1"
        alt=""
      ></img>
      <img
        src="assets/images/shapes/appointment-map-1-2.png"
        className="appointment-one__map-2"
        alt=""
      ></img>
      <img
        src="assets/images/resources/appointment-person-1-1.png"
        className="appointment-one__moc"
        alt=""
      ></img>
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-lg-6">
            <div className="block-title text-left">
              <p className="has-line">Connect With Us</p>
              <h3>
                

                Want to Know more About <br></br> Swarnim Ayu
              </h3>
            </div>
            <form
              action="http://html.tonatheme.com/2021/oberlin/assets/inc/sendemail.php"
              className="contact-one__form contact-form-validated"
            >
              <div className="row">
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="Full Name"
                    name="name"
                  ></input>
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="Email Address"
                    name="email"
                  ></input>
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="Phone Number"
                    name="phone"
                  ></input>
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="Best Time to Call"
                    name="phone"
                  ></input>
                </div>
               
                <div className="col-md-12">
                  <textarea name="message" placeholder="Message"></textarea>
                </div>
                <div className="col-md-12 text-left">
                  <button type="submit" className="thm-btn contact-one__btn">
                    Submit Now
                  </button>
                </div>
              </div>
            </form>
            <div className="result"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Appointment;
