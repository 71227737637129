import React, { useEffect } from "react";
import $ from "jquery";

function Testimonials() {
  useEffect(() => {
    $(document).ready(function () {
      if ($(".testimonials-two__thumb-carousel").length) {
        var testimonialsTwoThumbCarousel = new window.Swiper(
          ".testimonials-two__thumb-carousel",
          {
            slidesPerView: 3,
            spaceBetween: 0,
            mousewheel: true,
            speed: 1400,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            loop: true,
            autoplay: {
              delay: 5000,
            },
          }
        );
      }

      if ($(".testimonials-two__carousel").length) {
        var testimonialsTwoCarousel = new window.Swiper(
          ".testimonials-two__carousel",
          {
            observer: true,
            observeParents: true,
            speed: 1400,
            mousewheel: false,
            autoplay: {
              delay: 5000,
            },
            thumbs: {
              swiper: testimonialsTwoThumbCarousel,
            },
          }
        );
      }
    });
  }, []);

  return (
    <section
      className="testimonials-two"
      style={{
        backgroundImage: "url(assets/images/main-slider/main-slider-1-1.jpg)",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-8">
            <div
              className="testimonials-two__content wow fadeInUp"
              data-wow-duration="1500ms"
            >
              <div className="block-title">
                <p className="has-line">Testimonials</p>
                <h3>
                  What Our Clients Say <br></br> About Swarnim Ayu?
                </h3>
              </div>
              <div className="testimonials-two__carousel">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="testimonials-two__single">
                      <p>
                        Our goal each day is to ensure that our residents' needs
                        are not only met but exceeded. To make that happen, we
                        are committed to providing.
                      </p>
                      <h3>Rebeka Dawson</h3>
                      <span>Instructor</span>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonials-two__single">
                      <p>
                        Our goal each day is to ensure that our residents' needs
                        are not only met but exceeded. To make that happen, we
                        are committed to providing.
                      </p>
                      <h3>Lina Dean</h3>
                      <span>Instructor</span>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonials-two__single">
                      <p>
                        Our goal each day is to ensure that our residents' needs
                        are not only met but exceeded. To make that happen, we
                        are committed to providing.
                      </p>
                      <h3>Leon Cortez</h3>
                      <span>Instructor</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonials-two__thumb-carousel-wrap">
                <div className="testimonials-two__thumb-carousel">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <img
                        src="assets/images/resources/testi-3-1.jpg"
                        alt=""
                      ></img>
                    </div>
                    <div className="swiper-slide">
                      <img
                        src="assets/images/resources/testi-3-2.jpg"
                        alt=""
                      ></img>
                    </div>
                    <div className="swiper-slide">
                      <img
                        src="assets/images/resources/testi-3-3.jpg"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
