import React from "react";
import BestHomeCare from "../Components/BestHomeCare";
import PageBanner from "../Components/PageBanner";
import Servicelist from "../Components/Servicelist";
import Callnow from "../Components/Callnow";
function Legalservice() {
  return (
    <>
      <PageBanner name="Legal Service" />
      <section className="service-details">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <Servicelist />
                    </div>
                    <div className="col-lg-8">
                        <div className="service-details__main">
                            <div className="service-details__image">
                                <img src="assets/images/services/service-d-6-1.jpg" className="img-fluid" alt=""></img>
                            </div>
                            <div className="service-details__content">
                            <h3>Legal Services</h3>
                                <p>Swarnim Ayu, introduces Whitspan Law Offices LLP, as it's trusted partner to provide you legal support and services. Whether it  relates to property dispute, family matters, will, 
                                trusts, any disputes or any consultations about the protection of self and your wealth.</p>
                                <p>Whitespan has team of  lawyers, and chartered accountants, headed by Mr Vinay Shulka who has over 34 years of experience in legal and consulting.
                                </p>
                                 <div className="row">
                                    <div className="col-md-4">
                                    
                                    <p><h4>Contact Person:</h4>Mr Sukhpreet Singh </p>
                                    </div>
                                    <div className="col-md-4">
                                    <p><h4>Phone :</h4>0124 4016242 </p> 
                                    </div>
                                     <div className="col-md-4">
                                     <p><h4>e-Mail :</h4>Contact@whitespan.in <br></br></p>
                                    </div>
                                </div>                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Callnow />
    </>
  );
}

export default Legalservice;
