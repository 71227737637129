import React from "react";
import BestHomeCare from "../Components/BestHomeCare";
import Numbering from "../Components/Numbering";
import WhymostPeople from "../Components/WhymostPeople";
import PageBanner from "../Components/PageBanner";
import Callnow from "../Components/Callnow";
import { Link } from "react-router-dom";
function Services() {
  return (
    <>
      <PageBanner name="Services" />
      {/* <BestHomeCare /> */}
      

        <section className="service-one service-one__service-page">
            <div className="container">
                <div className="block-title text-center">
                    <p>Our Services</p>
                    <h3>Everyone Deserves Our <br></br> Best Services</h3>
                </div>
                <div className="row high-gutter">
                    <div className="col-lg-4 col-md-6">
                        <div className="service-one__single">
                            <div className="service-one__image">
                                <img src="assets/images/services/service-1-1.jpg" alt=""></img>
                            </div>
                            <div className="service-one__content">
                                <div className="service-one__icon">
                                    <i className="oberlin-icon-stethoscope"></i>
                                </div>
                                <h3> <Link to="/care-giver-medical">Care Giver - Medical</Link></h3>
                                 <Link to="/care-giver-medical" className="service-one__link">
                                 <i className="far fa-long-arrow-alt-right"></i></Link>
                                {/* <h3><a href="service-residential.html">Care Giver - Medical</a></h3>                                
                                <a href="service-residential.html" className="service-one__link"><i
                                        className="far fa-long-arrow-alt-right"></i></a> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-one__single">
                            <div className="service-one__image">
                                <img src="assets/images/services/service-1-2.jpg" alt=""></img>
                            </div>
                            <div className="service-one__content">
                                <div className="service-one__icon">
                                    <i className="oberlin-icon-exercise"></i>
                                </div>
                                <h3> <Link to="/care-giver-non-medical">Care Giver - Non Medical</Link></h3>
                                 <Link to="/care-giver-non-medical" className="service-one__link">
                                 <i className="far fa-long-arrow-alt-right"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-one__single">
                            <div className="service-one__image">
                                <img src="assets/images/services/service-1-3.jpg" alt=""></img>
                            </div>
                            <div className="service-one__content">
                                <div className="service-one__icon">
                                    <i className="oberlin-icon-disability"></i>
                                </div>
                                <h3> <Link to="/companion">Companion</Link></h3>
                                 <Link to="/care-giver-non-medical" className="service-one__link">
                                 <i className="far fa-long-arrow-alt-right"></i></Link>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-one__single">
                            <div className="service-one__image">
                                <img src="assets/images/services/service-1-4.jpg" alt=""></img>
                            </div>
                            <div className="service-one__content">
                                <div className="service-one__icon">
                                    <i className="oberlin-icon-brain"></i>
                                </div>
                                <h3> <Link to="/income">Income & Other Taxes</Link></h3>
                                 <Link to="/care-giver-non-medical" className="service-one__link">
                                 <i className="far fa-long-arrow-alt-right"></i></Link>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-one__single">
                            <div className="service-one__image">
                                <img src="assets/images/services/service-1-5.jpg" alt=""></img>
                            </div>
                            <div className="service-one__content">
                                <div className="service-one__icon">
                                    <i className="oberlin-icon-doctor"></i>
                                </div>
                                <h3> <Link to="/legal-service">Legal Services</Link></h3>
                                 <Link to="/legal-service" className="service-one__link">
                                 <i className="far fa-long-arrow-alt-right"></i></Link>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-one__single">
                            <div className="service-one__image">
                                <img src="assets/images/services/service-1-6.jpg" alt=""></img>
                            </div>
                            <div className="service-one__content">
                                <div className="service-one__icon">
                                    <i className="oberlin-icon-first-aid"></i>
                                </div>
                                <h3> <Link to="/home-improvement">Home Improvement</Link></h3>
                                 <Link to="/home-improvement" className="service-one__link">
                                 <i className="far fa-long-arrow-alt-right"></i></Link>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-one__single">
                            <div className="service-one__image">
                                <img src="assets/images/services/service-1-6.jpg" alt=""></img>
                            </div>
                            <div className="service-one__content">
                                <div className="service-one__icon">
                                    <i className="oberlin-icon-first-aid"></i>
                                </div>
                                <h3> <Link to="/equipment">Equipment Rental</Link></h3>
                                 <Link to="/equipment" className="service-one__link">
                                 <i className="far fa-long-arrow-alt-right"></i></Link>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-one__single">
                            <div className="service-one__image">
                                <img src="assets/images/services/service-1-6.jpg" alt=""></img>
                            </div>
                            <div className="service-one__content">
                                <div className="service-one__icon">
                                    <i className="oberlin-icon-first-aid"></i>
                                </div>
                                <h3> <Link to="/mutual-fund">Mutual Fund Investments</Link></h3>
                                 <Link to="/mutual-fund" className="service-one__link">
                                 <i className="far fa-long-arrow-alt-right"></i></Link>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-one__single">
                            <div className="service-one__image">
                                <img src="assets/images/services/service-1-6.jpg" alt=""></img>
                            </div>
                            <div className="service-one__content">
                                <div className="service-one__icon">
                                    <i className="oberlin-icon-first-aid"></i>
                                </div>
                                <h3> <Link to="/insurance">Insurance</Link></h3>
                                 <Link to="/insurance" className="service-one__link">
                                 <i className="far fa-long-arrow-alt-right"></i></Link>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-one__single">
                            <div className="service-one__image">
                                <img src="assets/images/services/service-1-6.jpg" alt=""></img>
                            </div>
                            <div className="service-one__content">
                                <div className="service-one__icon">
                                    <i className="oberlin-icon-first-aid"></i>
                                </div>
                                <h3> <Link to="/investment">Investment</Link></h3>
                                 <Link to="/investment" className="service-one__link">
                                 <i className="far fa-long-arrow-alt-right"></i></Link>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-one__single">
                            <div className="service-one__image">
                                <img src="assets/images/services/service-1-6.jpg" alt=""></img>
                            </div>
                            <div className="service-one__content">
                                <div className="service-one__icon">
                                    <i className="oberlin-icon-first-aid"></i>
                                </div>
                                <h3> <Link to="/asset">Assest Management</Link></h3>
                                 <Link to="/asset" className="service-one__link">
                                 <i className="far fa-long-arrow-alt-right"></i></Link>                               
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-one__single">
                            <div className="service-one__image">
                                <img src="assets/images/services/service-1-6.jpg" alt=""></img>
                            </div>
                            <div className="service-one__content">
                                <div className="service-one__icon">
                                    <i className="oberlin-icon-first-aid"></i>
                                </div>
                                <h3> <Link to="/oldagehome">Old Age Home - Residential</Link></h3>
                                 <Link to="/oldagehome" className="service-one__link">
                                 <i className="far fa-long-arrow-alt-right"></i></Link> 
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Callnow />
        {/* <section className="cta-two">
            <img src="assets/images/shapes/cta-1-1-shape-1.png" className="cta-two__dot-1" alt=""></img>
            <img src="assets/images/shapes/cta-1-1-shape-2.png" className="cta-two__dot-2" alt=""></img>
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-lg-6 d-flex justify-content-center align-items-center wow fadeInLeft"
                        data-wow-duration="1500ms">
                        <div className="cta-two__image">
                            <img src="assets/images/resources/cta-1-1.jpg" alt=""></img>
                            <div className="cta-two__image-inner">
                                <div className="cta-two__image-content">
                                    <h3><span className="counter">78</span></h3>
                                    <p>Success Rate</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="cta-two__content">
                            <h3>Book an <br></br> Appointment Today!</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                incididunt labore dolore magna aliqua enim ad minim veniam. </p>
                            <a href="#" className="thm-btn cta-two__btn">Get Appointment</a>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        <section className="career-one">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="career-one__block">
                            <div className="block-title">
                                <p className="has-line"> FAQ</p>
                                <h3>Freequently Asked <br></br> Questions</h3>
                            </div>
                            <p>Our goal each day is to ensure that our residents’ needs are not only met but exceeded.
                                To make that happen we are committed to providing an environment in which residents can
                                enjoy a wide range of amenities that</p>
                            <p>Allow them to feel independent safe and secure all while having fun! Our amenities
                                include.</p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="career-one__content">
                            <div className="accrodion-grp" data-grp-name="career-one__accrodion">
                                <div className="accrodion ">
                                    <div className="accrodion-title">
                                        <h4>Is there support for me at home?</h4>
                                    </div>
                                    <div className="accrodion-content">
                                        <div className="inner">
                                            <p>Our goal each day is to ensure that our residents’ needs are not only
                                                met.</p>
                                            <ul className="list-unstyled career-one__content-list">
                                                <li>Our goal each day is to ensure</li>
                                                <li>Our goal each day is to ensure</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accrodion active">
                                    <div className="accrodion-title">
                                        <h4>I am worried about a loved one</h4>
                                    </div>
                                    <div className="accrodion-content">
                                        <div className="inner">
                                            <p>Our goal each day is to ensure that our residents’ needs are not only
                                                met.</p>
                                            <ul className="list-unstyled career-one__content-list">
                                                <li>Our goal each day is to ensure</li>
                                                <li>Our goal each day is to ensure</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accrodion">
                                    <div className="accrodion-title">
                                        <h4>How can I manage my breathlessness?</h4>
                                    </div>
                                    <div className="accrodion-content">
                                        <div className="inner">
                                            <p>Our goal each day is to ensure that our residents’ needs are not only
                                                met.</p>
                                            <ul className="list-unstyled career-one__content-list">
                                                <li>Our goal each day is to ensure</li>
                                                <li>Our goal each day is to ensure</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accrodion">
                                    <div className="accrodion-title">
                                        <h4>I need help managing my pain</h4>
                                    </div>
                                    <div className="accrodion-content">
                                        <div className="inner">
                                            <p>Our goal each day is to ensure that our residents’ needs are not only
                                                met.</p>
                                            <ul className="list-unstyled career-one__content-list">
                                                <li>Our goal each day is to ensure</li>
                                                <li>Our goal each day is to ensure</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default Services;
