import React from 'react'
import PageBanner from "../Components/PageBanner";
function Contact() {
  return (
    <>
        <PageBanner name="Contact Us" />
        <section className="contact-info-one">
            <div className="container">
                <div className="block-title text-center">
                    <p>Contact Us</p>
                    <h3>Get In Touch</h3>
                </div>
                <div className="row">
                    <div className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms">
                        <div className="contact-info-one__single text-center">
                            <div className="contact-info-one__icon">
                                <i className="oberlin-icon-email"></i>
                            </div>
                            <h3>Email Address</h3>
                            <p><a href="mailto:animesh@Swarnim Ayu.com">care@Swarnim Ayu.com</a> </p>
                        </div>
                    </div>
                    <div className="col-lg-4 wow fadeInDown" data-wow-duration="1500ms">
                        <div className="contact-info-one__single text-center">
                            <div className="contact-info-one__icon">
                                <i className="oberlin-icon-phone"></i>
                            </div>
                            <h3>Phone Number</h3>
                            <p><a href="tel:+91 9811799499">+91 9266829800</a></p>
                        </div>
                    </div>
                    <div className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms">
                        <div className="contact-info-one__single text-center">
                            <div className="contact-info-one__icon">
                                <i className="oberlin-icon-marker"></i>
                            </div>
                            <h3>Office Address</h3>
                            <p>192, Sector 56, Gurgaon 122011</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="contact-one">
            <div class="container">
                <div class="block-title text-center">
                    <p>Fill form</p>
                    <h3>Book an Appointment</h3>
                </div>
                <form action="http://html.tonatheme.com/2021/oberlin/assets/inc/sendemail.php" class="contact-one__form contact-form-validated">
                    <div class="row">
                        <div class="col-md-6">
                            <input type="text" placeholder="Full Name" name="name"></input>
                        </div>
                        <div class="col-md-6">
                            <input type="text" placeholder="Email Address" name="email"></input>
                        </div>
                        <div class="col-md-6">
                            <input type="text" placeholder="Phone Number" name="phone"></input>
                        </div>
                        <div class="col-md-6">
                            <input type="text" placeholder="Appoinment for" name="appoinment for"></input>
                        </div>
                        {/* <div class="col-md-6">
                            <select name="discussion" class="selectpicker">
                                <option value="">Discussion For</option>
                                <option value="">Basic Query</option>
                                <option value="">Patient Admission</option>
                            </select>
                        </div> */}
                        <div class="col-md-12">
                            <textarea name="message" placeholder="Message"></textarea>
                        </div>
                        <div class="col-md-12 text-center">
                            <button type="submit" class="thm-btn contact-one__btn">Submit Now</button>
                            
                        </div>
                    </div>
                </form>
                <div class="result"></div>
            </div>
        </section>
        {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4562.753041141002!2d-118.80123790098536!3d34.152323469614075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e82469c2162619%3A0xba03efb7998eef6d!2sCostco+Wholesale!5e0!3m2!1sbn!2sbd!4v1562518641290!5m2!1sbn!2sbd"
            class="google-map__contact" allowfullscreen></iframe> */}

    </>
  )
}

export default Contact
